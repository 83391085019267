import { ClientOnly } from 'remix-utils/client-only';

import {
  Popover,
  PopoverButton,
  PopoverBackdrop,
  PopoverPanel,
} from '@headlessui/react';
import { Link } from '@remix-run/react';
import { AnimatePresence, motion } from 'framer-motion';
import { Container } from './container';
import { Button } from './button';
import { Logo } from './logo';
import { NavLinks } from './nav-links';

function MenuIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
      <path
        d="M5 6h14M5 18h14M5 12h14"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function ChevronUpIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
      <path
        d="M17 14l-5-5-5 5"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function MobileNavLink(
  props: Omit<
    React.ComponentPropsWithoutRef<typeof PopoverButton<typeof Link>>,
    'as' | 'className'
  >
) {
  return (
    <PopoverButton
      as={Link}
      className="block text-base leading-7 tracking-tight text-gray-700"
      {...props}
    />
  );
}

export function Header() {
  return (
    <ClientOnly>
      {() => (
        <header>
          <nav>
            <Container className="relative z-50 flex justify-between py-8">
              <div className="relative z-10 flex items-center gap-16">
                <Link to="/" aria-label="Home">
                  <Logo className="w-auto h-6" />
                </Link>
                <div className="hidden lg:flex lg:gap-10">
                  <NavLinks />
                </div>
              </div>
              <div className="flex items-center gap-6">
                <Popover className="lg:hidden">
                  {({ open }) => (
                    <>
                      <PopoverButton
                        className="relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 ui-not-focus-visible:outline-none"
                        aria-label="Toggle site navigation"
                      >
                        {({ open }) =>
                          open ? (
                            <ChevronUpIcon className="h-6 w-6" />
                          ) : (
                            <MenuIcon className="h-6 w-6" />
                          )
                        }
                      </PopoverButton>
                      <AnimatePresence initial={false}>
                        {open && (
                          <>
                            <PopoverBackdrop
                              static
                              as={motion.div}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                            />
                            <PopoverPanel
                              static
                              as={motion.div}
                              initial={{ opacity: 0, y: -32 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{
                                opacity: 0,
                                y: -32,
                                transition: { duration: 0.2 },
                              }}
                              className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20"
                            >
                              <div className="space-y-4">
                                <MobileNavLink to="/#features">
                                  Features
                                </MobileNavLink>
                                <MobileNavLink to="/#reviews">
                                  Reviews
                                </MobileNavLink>
                                <MobileNavLink to="/#pricing">
                                  Pricing
                                </MobileNavLink>
                                <MobileNavLink to="/#faqs">FAQs</MobileNavLink>
                              </div>
                              <div className="mt-8 flex flex-col gap-4">
                                <Button to="/login" variant="outline">
                                  Log in
                                </Button>
                                <Button to="#">Get Started</Button>
                              </div>
                            </PopoverPanel>
                          </>
                        )}
                      </AnimatePresence>
                    </>
                  )}
                </Popover>
                <Button
                  to="/login"
                  variant="outline"
                  className="hidden lg:block"
                >
                  Log in
                </Button>
                <Button to="#" className="hidden lg:block">
                  Get Started
                </Button>
              </div>
            </Container>
          </nav>
        </header>
      )}
    </ClientOnly>
  );
}
